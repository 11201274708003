import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import MainLayout from 'layouts/MainLayout'

import Button from 'components/Button'

import styles from './NotFound.module.scss'

const NotFoundPage = () => {
  const [t] = useTranslation(['main'])
  return (
    <MainLayout>
      <div className={styles.not_found}>
        <div className={styles.header}>
          <div className={styles.header_title}>{t('main:notFoundError')}</div>
          <div className={styles.header_subtitle}>404</div>
        </div>
        <div className={styles.content}>{t('main:notFoundPageNotFound')}</div>
        <div className={styles.return}>
          <Button href="/" className={styles.return_button}>
            {t('main:notFoundHomepage')}
          </Button>
        </div>
        <div className={styles.cable}>
          <img src="/images/cable.png" alt="torn cable" />
        </div>
      </div>
    </MainLayout>
  )
}
export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['main', 'shared'])),
    },
  }
}

export default NotFoundPage
